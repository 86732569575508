.gallery-landing {
  .bottom-top {
    position: fixed;
    height: 1px;
    bottom: 80px;
    width: calc(100% - 216px);
    left: 108px;
    background: #2D292780;
  }

  .abs-bottom {
    padding: 0 108px;
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
  }

  .sticky-nav {
    display: flex;
    padding: 20px 0 17px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    position: -webkit-sticky;
    position: sticky;
    top: 21px;
    z-index: 99;

    background: #FFF;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.12);

    .sticky-nav-body {
      display: flex;
      height: 19px;
      justify-content: center;
      align-items: flex-start;
      gap: 30px;
    }

    .sticky-nav-link {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      .sticky-nav-text {
        height: 19px;
        color: #2D2927;
        font-size: 13px;
        font-family: Adobe Caslon Pro;
        letter-spacing: 1.56px;
        text-transform: uppercase;
      }
    }
  }

  .content {
    padding: 60px 0;
    background: #FCFCFC;
  }

  .tab-wrapper {
    width: 100%;
    padding: 5px 0;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .tab {
      display: flex;
      flex-direction: row;

      .btn-tab {
        cursor: pointer;
        width: 128px;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;

        .tab-border {
          width: 100%;
          background: transparent;
          height: 3px;
          margin-top: 8px;
          justify-content: center;
          align-items: center;
          display: flex;

          .line-border {
            height: 1px;
            width: 100%;
            background: #000000;
          }

          .active {
            background: #59899D;
            height: 3px;
          }
        }
      }
    }
  }

  .gallery-content {
    height: 95%;
    overflow-y: scroll;
  }
}
