@import '../../common';

.wrap-sidenav {
  position: absolute;
  z-index: 111;
  width: fit-content;
  top: 112px;
  bottom: 112px;
  max-height: 800px;
  left: 0;
  color: #FFFFFF;

  .sidenav {
    width: 230px;
  }

  > div {
    height: 100%;
  }

  .sidenav__heading {
    padding: 0;
    margin-bottom: 30px;
    letter-spacing: 0.07rem;

    h2 {
      margin-bottom: 0;
      font-size: 27px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .sidenav__body {
    justify-content: start;
    line-height: 2.188;
    height: calc(100vh * (800/1024) - 130px);
    // flex: none;
  }

  .sidenav--dark {
    background: rgba(#343434, 0.9);

    .list-group-item {
      &:hover {
        opacity: 1;
      }
    }

    .list-group-heading {
      color: #ababab;
    }
  }

  .wrap-btn-detail .list-group .list-group-item,
  .list-group-item {
    padding: 0 .75rem;
  }

  .list-group-item {
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 200%; /* 28px */

    padding: 0 0;
    opacity: 1;
    &.list-group-heading {
      font-weight: 500;
    }

    &.active {
      opacity: 1;
      background-color: #000;
      color: #fff;
      border-radius: 0px;
      font-weight: 400;
    }
  }

  .list-group-heading {
    cursor: initial;
    flex-basis: 100%;
    letter-spacing: 0.12em;
  }

  .list-group-horizontal .list-group-item:not(.list-group-heading) {
    flex-basis: 30%;
  }

  #filter {
    position: relative;
    padding: 30px 25px;
    background-color: #01344B;

    .sidenav__body {
      gap: 25px;
      overflow-x: hidden;
      padding-bottom: 62px;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
    .sidenav__body::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
    .filter-group {
      border-top: 1px solid rgba(211, 208, 209, 0.25);
      padding-top: 10px;
      padding-bottom: 25px;
      display: flex;
      flex-direction: column;
      gap: 10px 10px;

      &.aspect {
        font-weight: 400;

        .name {
          font-size: 13px;
          font-family: 'Proxima Nova';
        }

        .description {
          font-size: 12px;
          color: #797979;
        }
      }

      .heading {
        font-family: Adobe Caslon Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 0;
      }

      .list-group {
        .list-group-item {
          border: 1px solid #E4E4E4;
          font-size: 14px;
          opacity: 1;
          
          &.border-none {
            border: none;
          }

          label {
            font-family: 'Proxima Nova';
          }
        }

        .list-group-number-item {
          cursor: pointer;
          border: 1px solid #E4E4E4;
          //padding: 1px 0;
          width: 23px;
          height: 23px;
          text-align: center;
          list-style-type: none;

          color: #FFF;
          font-family: Proxima Nova;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;

          &.active {
            background-color: #FFFFFF;
            color: #2D2927;
          }

        }

        .list-group-item-btn {
          cursor: pointer;
          margin-right: 5px;
          padding: 5px 10px;
          border: 1px solid #E4E4E4;
          text-align: center;
          list-style-type: none;

          color: #FFF;
          font-family: Proxima Nova;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 17.5px;
          flex-grow: 1;

          &.active {
            background-color: #FFFFFF;
            color: #2D2927;
          }

        }
      }

      .input-with-label {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
        flex: 1 0 0;
        background-color: transparent;
        border: 1px solid #ced4da;
        padding: 5px 10px;

        input::placeholder {
          color: #FFFFFF;
          opacity: 1;
        }

        .form-control {
          color: #FFF;
          background-color: transparent;
          border: none;
          padding: 0;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: uppercase;
        }

        .input-label {
          font-size: 10px;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.5px;
          font-family: 'Proxima Nova';
        }
      }
    }
    .btn-bottom {
      cursor: pointer;
      z-index: 9;
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: flex-start;
      width: 83%;
      background-color: #01344B;
      position: absolute;
      bottom: 30px;
      left: 25px;
      
      span {
        color: #FFF;
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 120%;
        text-transform: uppercase;
      }

      .disable {
        opacity: 0.3;
      }
    }
  }

  #lots {
    padding: 30px 25px;
    background-color: #01344B;

    .sidenav__body {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
    .sidenav__body::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }

    .list-group {
      padding-bottom: 50px;
    }

    .wrap-show-filter-btn {
      position: absolute;
      bottom: 0;
    }

    .btn-bottom {
      cursor: pointer;
      position: absolute;
      bottom: 1px;
      left: 0;
      right: 1px;
      background-color: #01344B;
      padding: 0 0 30px 0;
      z-index: 9;

      .show-filter {
        opacity: 0.3;
      }

      span {
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        margin-left: 25px;
        text-transform: uppercase;
      }
    }

    .list-group-item {
      &.active {
        background-color: transparent;
      }
    }
  }
}

.wrap-sidenav--right {
  left: unset;
  right: 0;
}

.wrap-btn-detail {
  width: 100%;
  text-align: left;
  color: #fff;

  .wrap-btn-detail-img {
    width: 85px;
    height: 40px;
    margin: 50px 0 60px 0;
  }

  .list-group {
    .list-group-item {
      font-family: Proxima Nova;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 31.5px; /* 31.5px */

      padding: 1.25rem 2rem;
      cursor: pointer;
      opacity: 1;

    }
  }
}

.wrap-btn-show-filter {
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  font-weight: 400;
  left: 0;
  right: 0;
  bottom: -2rem;
  z-index: 1120;

  p {
    margin: 0;

    b {
      color: #fff;
      text-decoration: underline;
    }
  }
}

#sidenavDetail {
  background-color: #01344B;
  padding: 0;

  .sidenav {
    &__heading {
      padding: 30px 30px 0 30px;
    }
    &__body {
      .list-group {
        padding-left: 30px;
        padding-right: 30px;
      }

      .list-group-item {
        padding: 0;
        opacity: 1;


      }

      & > .list-group .list-group-item {
        cursor: initial;
        border-top: 1px solid rgba(211, 208, 209, 0.25);
        margin: 0 0 20px 0;
      }

      .list-group-item-title {
        padding: 10px 0;
        font-family: Adobe Caslon Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
      }

      .list-group-item-body {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 17.5px */

        span {
          font-family: Proxima Nova;
        }
      }

      .list-group-item:first-child .list-group-item-body {
        text-transform: capitalize;
      }

      .list-group-item:last-child{
        border-radius: 0;
      }
    }
  }

  &__heading h2 {
    font-family: Adobe Caslon Pro;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.89px;
    text-transform: uppercase;
  }

  &.ru {
    width: 258px;
  }
}

.accordion-filter {
  .list-group-heading {
    font-size: 12px;

    &::after {
      content: '+';
      display: inline-block;
      margin-left: .5rem;
      margin-top: -3px;
    }

    &.open::after {
      content: '-';
    }
  }
}

.accordion-sidebar .service-charge {
  margin-top: 50px;

  b {
    font-family: 'Proxima Nova';
  }

  span {
    text-transform: none;
    font-size: 12px;
    font-family: 'Proxima Nova';
  }
}

.accordion-sidebar .room-area {
  margin-top: 50px;

  .list-group-item-title {
    display: none;
  }

  .list-group-item-body {
    text-transform: none!important;
  }

  .room-area-list {
    color: #FFF;
    font-family: Proxima Nova;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    list-style-type: none;

    li {
      margin-top: 10px;
      padding-left: 10px;
      display: flex;

      &::before {
        content: "• ";
        padding-top: 3px;
      }

      .content{
        margin-left: 5px;
        display: flex;
        flex-wrap: wrap;
        gap: 3px;

        span:nth-of-type(1) {
          white-space: nowrap;
        }
      }
    }
  }
}

.content .content-message .dropdown {
  width: 100%;
}
.scroll-unit{
  overflow-y: scroll;
  height: 95%;
}
.scroll-filter{
  overflow-y: scroll;
  height: 88%;
}
.scroll-detail{
  overflow-y: scroll;
  height: 90%;
}

.disable-scroll-bar{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.disable-scroll-bar::-webkit-scrollbar{
  display: none; // Safari and Chrome
}
