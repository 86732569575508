.multi-range-slider-container {
  height: 32px;
  display: flex;
  align-items: baseline;
  justify-content: center;
  position: relative;
  padding-top: 5px;
  touch-action: pan-x!important;
  z-index: 1;
  width: 175px;
  margin-left: 1px;

  .slider {
    position: relative;
    width: 100%;
  }

  .slider__track,
  .slider__range,
  .slider__left-value,
  .slider__right-value {
    position: absolute;
  }

  .slider__track,
  .slider__range {
    //border-radius: 3px;
    height: 1px;
  }

  .slider__track {
    background-color: #6B8FA3;
    width: 100%;
    z-index: 2;
  }

  .slider__range {
    background-color: #FFF;
    z-index: 3;
  }

  .slider__left-value,
  .slider__right-value {
    color: #FFF;
    font-family: Proxima Nova;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    position: absolute;

    top: 15px;
  }

  .slider__right-value {
    right: 2px;
  }

  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    height: 13px;
    width: 13px;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
  }

  .thumb--left {
    z-index: 3;
  }

  .thumb--right {
    z-index: 4;
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove the default appearance */
    appearance: none; /* Remove the default appearance */
    background-color: #FFF;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 13px;
    width: 13px;
    margin-top: 2px;
    z-index: 2;
    pointer-events: all;
    position: relative;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: #FFF;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 13px;
    width: 13px;
    margin-top: 2px;
    z-index: 2;
    pointer-events: all;
    position: relative;
  }
}
